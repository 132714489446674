import React, { useState } from 'react';
import { doc, updateDoc } from "firebase/firestore";
import { db } from './Firebase'; 

const ToggleButton = ({ productId, initialState }) => {
  const [isOn, setIsOn] = useState(initialState);

  const handleToggle = async () => {
    const newState = !isOn;
    setIsOn(newState);

    try {
      const productRef = doc(db, "products", productId);
      await updateDoc(productRef, {
        camp: newState
      });
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  return (
    <div className="flex items-center">
      <div 
        className={`relative w-14 h-8 flex items-center rounded-full p-1 cursor-pointer ${isOn ? 'bg-green-400' : 'bg-gray-300'}`}
        onClick={handleToggle}
      >
        <div 
          className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${isOn ? 'translate-x-6' : ''}`}
        ></div>
      </div>
    </div>
  );
};

export default ToggleButton;