// firebase.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore } from "@firebase/firestore";



const firebaseConfig = {
    apiKey: "AIzaSyAGZWidrl0xQCajISyJvh7x0JZoLf0JwMI",
    authDomain: "taf-mensucat.firebaseapp.com",
    projectId: "taf-mensucat",
    storageBucket: "taf-mensucat.appspot.com",
    messagingSenderId: "812398847353",
    appId: "1:812398847353:web:e954a4efba64317facbba8",
    measurementId: "G-VMJK1FSZ2T"
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const auth = getAuth(app);
  const db = getFirestore(app);

export { auth, db };
