import React from 'react';
import { GiConfirmed } from "react-icons/gi";
import { IoStatsChartSharp } from "react-icons/io5";
import { MdCardGiftcard } from "react-icons/md";






const Dashboard = () => {
  return (
    <div className="p-8 bg-gray-100 min-h-screen ml-[218px]">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white p-6 rounded-lg flex items-center text-green-800">
        <GiConfirmed size={40} />
        <div className="ml-2">
          <h2 className="text-xl font-semibold">YAYINDA</h2>
          <p className="text-gray-600">Site Durumu</p>
        </div>
        </div>

        <div className="bg-white p-6 rounded-lg flex items-center text-blue-900">
        <IoStatsChartSharp size={40} />
        <div className="ml-2">
          <h2 className="text-xl font-semibold">27</h2>
          <p className="text-gray-600">Günlük Ziyaretçi</p>
        </div>
        </div>

        <div className="bg-white p-6 rounded-lg flex items-center text-amber-900">
        <MdCardGiftcard size={40} />
        <div className="ml-2">
          <h2 className="text-xl font-semibold">11</h2>
          <p className="text-gray-600">Ürün Sayısı</p>
        </div>
        </div>

      </div>


      
      <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
       


        <div className="bg-white p-6 rounded-lg  md:col-span-3">
          <h2 className="text-lg font-semibold">Hakkımızda</h2>
          <h1>jsajd jksadhkjs adjksaj dk</h1>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;