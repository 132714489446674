import React from 'react'

const Settings = () => {
  return (
    <div className="p-8 bg-gray-100 min-h-screen ml-[218px]">

<div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
       
    <div className="bg-white p-6 rounded-lg  md:col-span-3">
         <h2 className="text-lg font-bold">Ayarlar</h2>
         <h1>Sitenin genel ayarlarına buradan ulaşabilirsiniz.</h1>
    </div>


</div>
    </div>
  )
}

export default Settings