import React from 'react'
import { RiChatSmile2Line, RiCrosshair2Fill, RiHazeLine, RiMedalLine } from "react-icons/ri";

const Info = () => {
  return (
    <div className="container mx-auto mt-24 px-4">
        <h1 className="text-3xl font-bold text-center">Hakkımızda</h1>
        <h2 className="text-xl font-normal text-center mt-4">
          Asfavori Asansör, 20 yıldır sektörde edindiği tecrübe ve uzman kadrosuyla, asansör 
          sistemlerinde güvenilir ve yenilikçi çözümler sunan bir firmadır. Müşteri memnuniyetini 
          ilke edinmiş olan Asfavori Asansör, projelerinize özel olarak tasarladığı çözümleriyle, 
          asansörlerinizin uzun ömürlü ve sorunsuz bir şekilde çalışmasını sağlar. Her projemizde 
          kaliteyi ve güvenliği ön planda tutarak, müşterilerimize en iyi hizmeti sunmayı amaçlıyoruz.
        </h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-12">
            {[
                { icon: RiChatSmile2Line, title: "Müşteri Memnuniyeti", description: "Müşterilerimizin ihtiyaçlarını tam olarak anlayarak, onlara özel çözümler sunar ve beklentilerinin üzerinde hizmet vermeyi hedefleriz. Güvenilir ve şeffaf iletişim kurarak, müşteri memnuniyetini en üst düzeye çıkarırız. Uzun soluklu iş ilişkileri kurarak, müşterilerimizin sadakatini kazanmak en büyük hedefimizdir." },
                { icon: RiCrosshair2Fill, title: "Kalite Odaklı", description: "Üretim süreçlerimizde en yüksek kalite standartlarını benimseyerek, kusursuz ürünler ortaya çıkarırız. Sürekli iyileştirme ilkesiyle hareket ederek, ürün ve hizmetlerimizin kalitesini daha da yükseltiriz. Kaliteli malzemeler kullanarak, ürünlerimizin uzun ömürlü olmasını sağlarız." },
                { icon: RiHazeLine, title: "Çevre Duyarlılığı", description: "Üretim süreçlerimizde çevreye duyarlı teknolojiler kullanarak, doğal kaynakları koruruz. Atık yönetimi konusunda hassas davranarak, çevre kirliliğini önleriz. Sürdürülebilir bir gelecek için, çevre dostu ürünler geliştirmeye özen gösteririz." },
                { icon: RiMedalLine, title: "İnovasyon ve Teknoloji", description: "Sektördeki son teknolojik gelişmeleri yakından takip ederek, ürün ve hizmetlerimize yansıtırız. Ar-Ge çalışmalarına yatırım yaparak, sektöre yenilikler kazandırırız. Müşterilerimize daha konforlu ve pratik çözümler sunmak için, teknolojiden faydalanırız." }
            ].map((item, index) => (
                <div className="flex flex-col items-center justify-center mt-14">
                    <div className="bg-blue-900 h-16 w-16 rounded-lg flex items-center justify-center">
                        <item.icon size={40} color='white' />
                    </div>
                    <h2 className="text-center mt-3 font-semibold">{item.title}</h2>
                    <p className="text-center w-9/12">{item.description}</p>
                </div>
            ))}
        </div>
    </div>
  )
}

export default Info