import React from 'react'

const Product = () => {
  return (
    <div className="mx-auto mt-36 bg-oval bg-contain">
      <div className="mx-auto container grid grid-cols-1 md:grid-cols-2">
        <div className="flex flex-col justify-center p-4">
          <h1 className="text-3xl font-bold text-left">
            Ürünlerimizin arkasındayız.
          </h1>
          <p className="text-xl font-normal text-left mt-4">
            Ürünlerimizin her aşamasında kaliteyi ön planda tutar, güvenilirliğinden emin olarak sizlere sunarız.
            Ürettiğimiz her ürünün arkasında durur, memnuniyetinizi garanti ederiz.
            Müşteri memnuniyeti bizim için birinci önceliktir. Bu nedenle ürünlerimizin kalitesine güvenir ve arkasında dururuz.
          </p>
        </div>
        <div className="flex flex-wrap justify-center items-center gap-4 p-4">
          <img src="/img/7.jpg" alt="Background" className="h-40 w-40 rounded-lg shadow-2xl" />
          <img src="/img/8.jpg" alt="Background" className="h-24 w-24 rounded-lg shadow-2xl" />
          <img src="/img/9.jpg" alt="Background" className="h-36 w-36 rounded-lg shadow-2xl" />
          <img src="/img/10.jpg" alt="Background" className="h-32 w-32 rounded-lg shadow-2xl" />
          <img src="/img/11.jpg" alt="Background" className="h-40 w-40 rounded-lg shadow-2xl" />
        </div>
      </div>
    </div>
  )
}

export default Product