import React from 'react'

const HomeAbout = () => {
  return (
    <div className="mx-auto container grid md:grid-cols-2 grid-cols-1 items-center mb-24">
      <div className="py-12 px-6">
          <h2 className="text-xl md:text-2xl font-semibold text-orange">Hakkımızda</h2>
          <p className="mt-4 text-blue-900 text-3xl md:text-4xl font-bold">
            Güvenle<br />Yükselen Başarı
          </p>
      </div>
      <div className="md:mt-6 mt-1 text-gray-600 px-4 md:px-0">
        <p>As Favori Asansör olarak, uzman ekibimizle projelerinize özel çözümler sunuyor, montaj ve bakım hizmetlerimizle asansörlerinizin uzun ömürlü ve sorunsuz çalışmasını sağlıyoruz.</p>
        <p className="mt-4">Müşteri memnuniyetini her zaman ön planda tutarak, kalite ve güvenliği bir araya getiren hizmet anlayışımızla siz değerli müşterilerimizin yanındayız.</p>
      </div>
    </div>
  )
}

export default HomeAbout;