import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Modal from './../components/PopUpModal';

function Header() {
  const location = useLocation();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('.menu-button, .menu-content')) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, []);

  // Dinamik burger icon rengini belirle
  const burgerIconColor = location.pathname === '/' ? 'text-white' : 'text-black';

  return (
    <div className="relative w-full z-50">
      <div className="container mx-auto flex justify-between items-center h-[100px] px-4">
        <a href="/">
          <img className="h-[50px] md:h-[79px]" src={location.pathname === '/' ? './img/beyazasfavori.png' : './img/asfavorilogo.png'} alt="logo" />
        </a>
        <ul className={`hidden md:flex space-x-6 items-center font-semibold ${location.pathname === '/' ? 'text-white' : 'text-black'}`}>
          <li><Link to="/">Anasayfa</Link></li>
          <li><Link to="/hakkimizda">Hakkımızda</Link></li>
          <li><Link to="/urunler">Hizmetler</Link></li>
          {/* <li><Link to="/yonetim/ayarlar">Admin</Link></li> */}
          <li><Link to="/iletisim">İletişim</Link></li>
          <li className="cursor-pointer border-2 rounded-lg p-2" onClick={() => setModalOpen(true)}>TEKLİF AL</li>
        </ul>
        <button onClick={toggleMenu} className={`text-3xl md:hidden menu-button ${burgerIconColor}`}>&#9776;</button>
      </div>
      {isMenuOpen && (
        <div className="absolute top-full left-0 w-full bg-gray-800 text-white p-5 menu-content md:hidden">
          <ul className="flex flex-col items-center space-y-4">
            <li><Link to="/" onClick={toggleMenu}>Anasayfa</Link></li>
            <li><Link to="/hakkimizda" onClick={toggleMenu}>Hakkımızda</Link></li>
            <li><Link to="/urunler" onClick={toggleMenu}>Hizmetler</Link></li>
            {/* <li><Link to="/yonetim/ayarlar" onClick={toggleMenu}>Admin</Link></li> */}
            <li><Link to="/iletisim" onClick={toggleMenu}>İletişim</Link></li>
            <li onClick={() => { setModalOpen(true); toggleMenu(); }}>TEKLİF AL</li>
          </ul>
        </div>
      )}
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </div>
  );
}

export default Header;