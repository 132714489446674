import React, { useState, useEffect } from 'react';
import { MdDeleteForever, MdDeleteSweep, MdOutlineCancel } from "react-icons/md";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from './../Firebase'; 
import ToggleButton from '../ToggleButton';

const PopupForm = ({ onClose }) => {
  const [productName, setProductName] = useState('');
  const [productFeature, setProductFeature] = useState('');
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [products, setProducts] = useState([]);



  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImage(file);
      setImageUrl(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!image) {
      alert("Lütfen bir ürün görseli yükleyin.");
      return;
    }

    try {
      const storage = getStorage();
      const storageRef = ref(storage, `images/${image.name}`);
      await uploadBytes(storageRef, image);
      const downloadURL = await getDownloadURL(storageRef);

      await addDoc(collection(db, "products"), {
        name: productName,
        feature: productFeature,
        imageUrl: downloadURL,
        camp: false
      });

      alert('Ürün başarıyla eklendi!');
      onClose();
    } catch (error) {
      console.error("Hata: ", error);
      alert('Ürün eklenirken bir hata oluştu!');
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <div className="justify-between flex">
          <h2 className="text-lg font-bold mb-4">Hizmet Ekle</h2>
          <div className="text-red-600 cursor-pointer" onClick={onClose}><MdOutlineCancel size={28} /></div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Hizmet Adı:</label>
            <input 
              type="text"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              className="w-full border border-gray-300 p-2 rounded mt-1"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Özellik:</label>
            <input 
              type="text"
              value={productFeature}
              onChange={(e) => setProductFeature(e.target.value)}
              className="w-full border border-gray-300 p-2 rounded mt-1"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Ürün Görseli:</label>
            <input 
              type="file"
              onChange={handleImageChange}
              className="w-full border border-gray-300 p-2 rounded mt-1"
              required
            />
            {imageUrl && <img src={imageUrl} alt="Ürün Görseli" className="mt-4 w-24 h-auto rounded" />}
          </div>
          <div className="flex justify-end">
            <button type="submit" className="bg-ucdortbes text-black px-6 py-3 rounded-full font-semibold">Ekle</button>
          </div>
        </form>
      </div>
    </div>
  );
};

const AddProducts = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [products, setProducts] = useState([]);

  const handlePopupOpen = () => {
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, "products"));
      const productsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProducts(productsList);
    };

    fetchProducts();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm('Bu ürünü silmek istediğinizden emin misiniz?')) {
      try {
        const productDocRef = doc(db, "products", id);
        await deleteDoc(productDocRef);
        setProducts(products.filter(product => product.id !== id));
        console.log('Ürün başarıyla silindi');
      } catch (error) {
        console.error('Ürün silinirken bir hata oluştu:', error);
        alert('Ürün silinirken bir hata oluştu, lütfen tekrar deneyin.');
      }
    }
  };

  return (
    <div className="p-8 bg-gray-100 min-h-screen ml-[218px]">
      <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="bg-white p-6 rounded-lg md:col-span-3 container mx-auto">
          <div className="flex justify-between items-start">
            <div>
              <h2 className="text-lg font-bold">Hizmetler</h2>
              <h1>Sayfanızda gözükecek ürünleri veya hizmetleri buradan ekleyebilirsiniz.</h1>
            </div>
            <button 
              onClick={handlePopupOpen}
              className="bg-ucdortbes text-black px-6 py-3 rounded-full font-semibold"
            >
              Ekle
            </button>
          </div>

          <div className="container mx-auto p-6">
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-200 rounded-lg">
                <thead className="bg-gray-800 text-white">
                  <tr>
                    <th className="py-3 px-4 text-left"><input type="checkbox" className="form-checkbox h-5 w-5 text-blue-600"/></th>
                    <th className="py-3 px-4 text-left">Hizmet</th>
                    <th className="py-3 px-4 text-left">Özelliği</th>
                    <th className="py-3 px-4 text-left">Öne Çıkar</th>
                    <th className="py-3 px-4 text-left"><MdDeleteSweep size={26} /></th>
                  </tr>
                </thead>
                <tbody>
                  {products.map(product => (
                    <tr key={product.id} className="border-b border-gray-200 hover:bg-gray-100">
                      <td className="py-3 px-4"><input type="checkbox" className="form-checkbox h-5 w-5 text-blue-600"/></td>
                      <td className="py-3 px-4">{product.name}</td>
                      <td className="py-3 px-4">{product.feature}</td>
                      <td className="py-3 px-4"><ToggleButton productId={product.id} initialState={product.camp} /></td>
                      <td className="py-3 px-4">
                      <button onClick={() => handleDelete(product.id)} className="text-red-500 hover:text-red-700">
                        <MdDeleteForever size={26} />
                      </button>
                    </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {isPopupOpen && <PopupForm onClose={handlePopupClose} />}
    </div>
  );
};

export default AddProducts;