import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Brands = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 1024, // Tablet ve daha küçük cihazlar için
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 768, // Mobil cihazlar için
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 480, // Küçük mobil cihazlar için
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            }
        ]
    };

    return (
        <div className="relative">
            <img className="w-screen absolute" src='img/Wave2.svg' alt="Wave" />
            <img className="w-screen absolute mt-[190px]" src='img/Base2.png' alt="Base" />
            <div className="z-10 mx-auto container relative px-4" style={{ top: '190px' }}>
                <h1 className="font-bold text-2xl w-full md:w-[620px]">
                    Yıllardır birçok site bize güvendi. Asfavori ailesi olarak 7/24 sizlerin hizmetindeyiz.
                </h1>
            </div>
            <div className="container mx-auto relative mt-[220px]">
                <Slider {...settings}>
                    <div className="px-4">
                        <img src="/img/b1.png" alt="Otis" className="h-[50px] mx-auto" />
                    </div>
                    <div className="px-4">
                        <img src="/img/b2.png" alt="KONE" className="h-[50px] mx-auto" />
                    </div>
                    <div className="px-4">
                        <img src="/img/b3.png" alt="Schindler" className="h-[50px] mx-auto" />
                    </div>
                    <div className="px-4">
                        <img src="/img/b1.png" alt="ThyssenKrupp" className="h-[50px] mx-auto" />
                    </div>
                    <div className="px-4">
                        <img src="/img/b2.png" alt="Hyundai" className="h-[50px] mx-auto" />
                    </div>
                    <div className="px-4">
                        <img src="/img/b3.png" alt="Mitsubishi" className="h-[50px] mx-auto" />
                    </div>
                    <div className="px-4">
                        <img src="/img/b1.png" alt="Hitachi" className="h-[50px] mx-auto" />
                    </div>
                </Slider>
            </div>
            <div className="border-b border-gray-300 mt-8 mx-auto container"></div>
        </div>
    )
}

export default Brands;