import React from 'react'
import { Link} from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import { auth } from '../Firebase';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth/cordova'; 
import { onAuthStateChanged } from 'firebase/auth'; 



const LogIn = () => {

 
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        await signInWithEmailAndPassword(auth, email, password);
        navigate('/yonetim/istatistikler'); 
      } catch (error) {
        setError(error.message);
      }
    };

    useEffect(() => {
      onAuthStateChanged(auth, (currentUser) => {
          if (currentUser) {
            navigate('/yonetim/istatistikler');
          } else {
              navigate("/admin");
          }
      });
  }, [navigate]);


  return (
    <div>  
         
        <div className=" mt-5 ml-5">
        <Link to="/" className="flex items-center space-x-2"><IoIosArrowBack /> <h1>Geri Dön</h1></Link>
        </div>
    <div className="bg-home-bg bg-center bg-cover h-screen flex justify-center items-center">
             <div className="flex justify-center items-center h-screen">
                 <div className="text-center">
                 <h1 className="text-2xl font-thin mb-12">Yönetim Paneli<span className="text-primary-first text-3xl font-extrabold">.</span></h1>
                 
                 <form onSubmit={handleSubmit}>
                 <label className="block relative mb-2 w-80">
                   <input type="text" name="email" className="bg-primary-third border border-primary-fourth w-full h-[38px] rounded-md focus:border-primary-second outline-none px-2 peer pt-3 opacity-50" value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required/>
                   <small className="absolute top-[-1px] left-[9px] translate-y-1/2 text-s text-primary-sixth cursor-text pointer-events-none transition-all peer-valid:text-[10px] peer-valid:-translate-y-[-2px] peer-focus:text-[10px] peer-focus:-translate-y-[-2px]">E-posta</small>
                 </label>
     
                 <label className="block relative mb-2 w-80">
                   <input type="password" name="password" className="bg-primary-third border border-primary-fourth w-full h-[38px] rounded-md focus:border-primary-second outline-none px-2 peer pt-3 opacity-50" required value={password}
                  onChange={(e) => setPassword(e.target.value)}/>
                   <small className="absolute top-[-1px] left-[9px] translate-y-1/2 text-s text-primary-sixth cursor-text pointer-events-none transition-all peer-valid:text-[10px] peer-valid:-translate-y-[-2px] peer-focus:text-[10px] peer-focus:-translate-y-[-2px]">Şifre</small>
                 </label>
                     <button type="submit" className="bg-primary-first border border-primary-second rounded-md rounded-primary-second w-full h-[38px] text-primary-third font-semibold hover:bg-primary-second">Giriş Yap</button>
                     
                 </form>
                 </div>
             </div>
    
     </div>
    </div>  
  )
}

export default LogIn