import React from 'react'

const Intro = () => {
  return (
    <div className="relative w-full mt-[-100px] z-0">
      <img className="w-full md:h-auto h-[600px] object-cover" src='img/asansor.jpg' alt="Background"/>
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <img className="absolute bottom-[-5px] w-full" src='img/wave.svg' alt="Wave" />
      <div className="absolute inset-0 flex justify-center items-center px-4">
        <div className="text-center">
          <h1 className="text-white text-3xl md:text-5xl lg:text-6xl font-bold">Asansörde Güvenin Adı: ASFAVORİ</h1>
          <p className="text-white mt-4 text-xl md:text-2xl lg:text-3xl">A+S = Asansörün Sağlamı</p>
          <button className="mt-8 bg-white text-black px-6 py-3 rounded-full font-semibold text-sm md:text-base lg:text-lg">Hikayemiz</button>
        </div>
      </div>
    </div>
  )
}

export default Intro;