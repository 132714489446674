import React from 'react'
import Slider from "react-slick";

const AboutUs = () => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            }
        ]
    };

    return (
        <div className="h-full container mx-auto p-4">
            <div className="sm:w-[730px] w-full">
                <h1 className="font-bold text-5xl mt-40 text-blue-900">Hakkımızda</h1>
                <p className="font-normal text-lg mt-10 px-4 md:px-0">
                    Asfavori Asansör, sektördeki uzun yıllara dayanan deneyimi ve yenilikçi bakış açısıyla, müşterilerine güvenli, konforlu ve modern asansör çözümleri sunmaktadır. Şirketimiz, kurulduğu günden itibaren kaliteyi ve müşteri memnuniyetini ön planda tutarak, sektörde saygın bir konuma ulaşmıştır.
                </p>
            </div>
            <div className="border-b border-gray-500 mt-20 container mx-auto" style={{ borderColor: "rgba(169, 169, 169, 0.5)" }}></div>

            <div className="grid sm:grid-cols-4 grid-cols-1 justify-center items-center gap-4 mt-20 px-4 md:px-0">
                <div className="flex flex-col sm:p-4 p-2">
                    <h1 className="font-bold text-lg">Misyonumuz:</h1>
                    <p>
                        Müşterilerimize, yaşam alanlarını daha güvenli ve konforlu hale getirecek, teknolojik gelişmeleri takip eden, yüksek kaliteli ve estetik asansör çözümleri sunmak. Sektörde öncü bir rol üstlenerek, asansör teknolojilerine yenilikler katmak ve müşteri beklentilerinin üzerinde hizmet vermektir.
                    </p>
                </div>
                <div className="flex flex-col sm:p-4 p-2">
                    <h1 className="font-bold text-lg">Vizyonumuz:</h1>
                    <p>
                        Asfavori Asansör olarak, Türkiye’nin önde gelen asansör üreticilerinden biri olmak ve uluslararası arenada tanınan bir marka haline gelmektir. Müşteri memnuniyetini en üst düzeyde tutarak, sektörde kalıcı bir yer edinmek ve büyümeye devam etmek temel hedefimizdir.
                    </p>
                </div>
                <div className="flex flex-col sm:p-4 p-2">
                    <h1 className="font-bold text-lg">Kalite</h1>
                    <p>
                        Üretim süreçlerimizde en yüksek kalite standartlarını benimsiyoruz. Kullandığımız malzemeler, uyguladığımız üretim yöntemleri ve gerçekleştirdiğimiz testler, ürünlerimizin uzun ömürlü ve güvenilir olmasını sağlıyor. TUV-SÜD gibi bağımsız kuruluşlardan aldığımız sertifikalar, kalite anlayışımızın bir göstergesidir.
                    </p>
                </div>
                <div className="flex flex-col sm:p-4 p-2">
                    <h1 className="font-bold text-lg">Tecrübe</h1>
                    <p>
                        Uzun yılların verdiği tecrübeyle, müşterilerimizin ihtiyaçlarını en iyi şekilde anlıyor ve onlara özel çözümler üretiyoruz. Projelendirme aşamasından teslim ve bakım süreçlerine kadar her adımda, müşteri memnuniyetini ön planda tutuyoruz.
                    </p>
                </div>
            </div>

            <div className="w-full" name="kampanya">
                <div className="flex flex-col items-center justify-center">
                    <div className="text-center font-extrabold text-4xl mt-40 sm:w-[730px] w-full px-4 md:px-0">
                        "Asfavori Asansör olarak, başarımızın arkasındaki en büyük gücümüz, deneyimli ve uzman ekibimizdir. Her biri alanında uzmanlaşmış mühendisler, teknisyenler ve destek personelimizle, müşterilerimize en iyi hizmeti sunmak için çalışıyoruz."
                    </div>
                </div>
            </div>

            <div className="z-10 mx-auto container relative mt-40">
                <h1 className="text-blue-900 font-bold text-5xl mb-7 px-4 md:px-0">
                    Müşteri Memnuniyetinin Adresi
                </h1>
                <h1 className="font-normal text-xl px-4 md:px-0">
                    Yılların verdiği tecrübe ve uzmanlığımızla tamamladığımız sayısız proje, referanslarımızla kanıtlanmaktadır. Kamu kurumlarından özel sektöre, konut projelerinden ticari yapılara kadar geniş bir yelpazede başarılı işlere imza attık. Müşterilerimizden aldığımız olumlu geri bildirimler, kalitemizin en büyük göstergesidir. Referanslarımız için web sitemizi ziyaret edebilirsiniz.
                </h1>
            </div>

            <div className="container mx-auto relative mt-[80px] sm:mt-[280px]">
                <Slider {...settings}>
                    <div>
                        <img src="/img/r1.png" alt="Otis" className="h-[70px] rounded-lg mx-auto" />
                    </div>
                    <div>
                        <img src="/img/r2.png" alt="KONE" className="h-[70px] rounded-lg mx-auto" />
                    </div>
                    <div>
                        <img src="/img/r3.png" alt="Schindler" className="h-[70px] rounded-lg mx-auto" />
                    </div>
                    <div>
                        <img src="/img/r4.png" alt="ThyssenKrupp" className="h-[70px] rounded-lg mx-auto" />
                    </div>
                    <div>
                        <img src="/img/r3.png" alt="Hyundai" className="h-[70px] rounded-lg mx-auto" />
                    </div>
                    <div>
                        <img src="/img/r1.png" alt="Mitsubishi" className="h-[70px] rounded-lg mx-auto" />
                    </div>
                    <div>
                        <img src="/img/r3.png" alt="Hitachi" className="h-[70px] rounded-lg mx-auto" />
                    </div>
                </Slider>
            </div>
        </div>
    )
}

export default AboutUs;