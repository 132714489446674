import React from 'react'

export default function Elevator() {
  return (
    <div className="bg-black">
        <div className="mx-auto container flex flex-col md:flex-row justify-between p-5 md:p-20 mt-20 md:mt-80">
            <div className="text-white md:w-3/5 flex flex-col justify-center">
                <h1 className="font-bold text-xl md:text-2xl ">Asfavori Asansör, sektördeki uzun yıllara dayanan deneyimi ve uzman kadrosuyla, müşterilerine sunduğu asansörlerin kalitesiyle öne çıkmaktadır. İşte Asfavori asansörlerinin kalitesini belirleyen başlıca faktörler:</h1>
                <ul className="mt-6 space-y-4">
                    <li className="font-thin"><span className="font-bold">Teknolojik Donanım:</span> Asfavori asansörleri, en son teknolojiyle donatılmıştır. Akıllı kontrol sistemleri, enerji verimliliği ve güvenlik özellikleri, modern yaşamın gerektirdiği tüm ihtiyaçları karşılar.</li>
                    <li className="font-thin"><span className="font-bold">Üretim Süreçleri:</span> Modern üretim tesislerinde, titizlikle yürütülen üretim süreçleri sayesinde, her bir asansör, en ince ayrıntısına kadar kontrol edilir.</li>
                    <li className="font-thin"><span className="font-bold">Kişiselleştirilmiş Çözümler:</span> Asfavori, müşterilerinin ihtiyaçlarına ve binaların mimarisine uygun, özel tasarımlı asansörler sunar.</li>
                    <li className="font-thin"><span className="font-bold">Mühendislik Desteği:</span> Deneyimli mühendislerimiz, projelerinizin her aşamasında size özel çözümler sunar.</li>
                    <li className="font-thin"><span className="font-bold">Malzeme Kalitesi:</span> Asfavori, asansörlerinde en yüksek kalite standartlarına sahip malzemeleri kullanır. Paslanmaz çelik, alüminyum ve diğer dayanıklı malzemeler, asansörlerin uzun ömürlü ve güvenilir olmasını sağlar.</li>
                </ul>
            </div>

            <div className="text-white mt-6 md:mt-0">
            <img src="/img/asansor.png" alt="Otis" className="w-full md:w-[550px] h-auto object-contain" />
            </div>

        </div>
    </div>
  )
}