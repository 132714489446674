import React, { useState } from 'react';
import { db } from './../components/Firebase'; 
import { collection, addDoc } from 'firebase/firestore';

const Modal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const form = async (event) => {
    event.preventDefault(); 
    const { name, surname, phone, city, district } = event.target.elements;

    try {
      await addDoc(collection(db, "teklifler"), {
        name: name.value,
        surname: surname.value,
        phone: phone.value,
        city: city.value + '' + district.value,
      });
      alert("Teklif başarıyla kaydedildi!");
      onClose(); 
    } catch (error) {
      console.error("Teklif kaydedilirken hata oluştu: ", error);
      alert("Teklif yetkililere iletildi.");
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center px-4">
      <div className="bg-white p-8 rounded-lg relative w-full max-w-lg mx-auto">
        <button onClick={onClose} className="absolute top-2 right-4 text-4xl font-bold text-black hover:text-blue-700 p-2 rounded-full">&times;</button>
        <h1 className="text-2xl font-bold mt-5 text-center">Teklif Formu</h1>
        <p className="text-center">Formu doldurduktan hemen sonra arkadaşımız sizi arayacak.</p>
       
        <form className="mt-6" onSubmit={form}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-bold text-gray-700">Ad:</label>
            <input type="text" name="name" className="mt-1 p-2 w-full border rounded-md" placeholder="Adınız" required />
          </div>
          <div className="mb-4">
            <label htmlFor="surname" className="block text-sm font-bold text-gray-700">Soyad:</label>
            <input type="text" name="surname" className="mt-1 p-2 w-full border rounded-md" placeholder="Soyadınız" required />
          </div>
          <div className="mb-4">
            <label htmlFor="phone" className="block text-sm font-bold text-gray-700">Telefon:</label>
            <input type="tel" name="phone" className="mt-1 p-2 w-full border rounded-md" placeholder="Telefon Numaranız" required />
          </div>
          <div className="mb-4">
            <label htmlFor="city" className="block text-sm font-bold text-gray-700">İl:</label>
            <input type="text" name="city" className="mt-1 p-2 w-full border rounded-md" placeholder="İliniz" required />
          </div>
          <div className="mb-4">
            <label htmlFor="district" className="block text-sm font-bold text-gray-700">İlçe:</label>
            <input type="text" name="district" className="mt-1 p-2 w-full border rounded-md" placeholder="İlçeniz" required />
          </div>
          <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-6">
            Gönder
          </button>
        </form>

      </div>
    </div>
  );
};

export default Modal;