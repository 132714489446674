import React from 'react'
import Map, {Marker} from 'react-map-gl';
import { FaFacebook, FaInstagram, FaTwitter, FaTiktok } from 'react-icons/fa';



const contact = () => {
  return (
    <div className="mt-2">
      
      <Map
      mapboxAccessToken="pk.eyJ1IjoidGlyaXR0dHR0IiwiYSI6ImNsdzB1d2RoYjA0dWoyaW51NGhzZ2NrN2YifQ.UyY8USTiTCejqMgcYDy0wQ"
      initialViewState={{
        latitude: 41.020557,
        longitude: 28.540281,
        zoom: 11,
        bearing: 0,
        pitch: 50,

        
      }}
      style={{position: 'relative', width: '100%', height: 400, position: 'relative',}}
      mapStyle="mapbox://styles/mapbox/dark-v9"
      >
      <Marker latitude={41.020557} longitude={28.540281} anchor="bottom" >
      <img src="/img/map.png" alt="Map Marker"  />
      </Marker>
     
    </Map>

    <div className="grid sm:grid-cols-4 justify-center gap-4  mt-20 mx-auto container">
        <div className="flex flex-col  sm:p-3">
                <h1 className="font-bold text-lg tracking-widest text-gray-400">ADRES</h1>
                <p className="mt-9 w-[300px]" >Murat Çeşme, Sultan Murat Cd. 48-B, 34535 Büyükçekmece/İstanbul</p>
            </div>

            <div className="flex flex-col  sm:p-3 tracking-widest">
                <h1 className="font-bold text-lg tracking-widest text-gray-400 ">TELEFON</h1>
               
                <p className="mt-8 ">0541 214 34 08</p>
                <p className=" ">0545 215 34 08</p>
            </div>
            <div className="flex flex-col  sm:p-3 tracking-widest">
                <h1 className="font-bold text-lg tracking-widest text-gray-400 ">E-POSTA</h1>
               
                <p className="mt-8 ">info@asfavoriasansor.com</p>
            </div>

            <div className="flex flex-col  sm:p-3 tracking-widest pb-24">
                <h1 className="font-bold text-lg tracking-widest text-gray-400 ">SOSYAL MEDYA</h1>
                <div className="mt-9 text-gray-400 flex" >
                <div className="flex gap-x-2">  
            {/* <div className="bg-gray-300 h-[32px] w-[32px] rounded-full flex items-center justify-center hover:bg-blue-900 hover:text-white text-gray-400">
              <FaFacebook size={18} />
            </div>
            <div className="bg-gray-300 h-[32px] w-[32px] rounded-full flex items-center justify-center hover:bg-blue-900 hover:text-white text-gray-400">
              <FaInstagram size={18} />
            </div>
            <div className="bg-gray-300 h-[32px] w-[32px] rounded-full flex items-center justify-center hover:bg-blue-900 hover:text-white text-gray-400">
              <FaTwitter size={18} />
            </div>
            <div className="bg-gray-300 h-[32px] w-[32px] rounded-full flex items-center justify-center hover:bg-blue-900 hover:text-white text-gray-400">
              <FaTiktok size={18} />
            </div> */} 
            <a href='https://www.instagram.com/as.favori.asansor/'>
            <div className="bg-gray-300 h-[32px] w-[32px] rounded-full flex items-center justify-center hover:bg-blue-900 hover:text-white text-gray-400">
              <FaInstagram size={18} />
            </div>
            </a>
          </div>
                </div>
            </div>

    </div>

    </div>
  )
}

export default contact