import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Brands from './components/Brands';
import Footer from './components/Footer';
import Header from './components/Header';
import HomeAbout from './components/HomeAbout';
import Info from './components/Info';
import Intro from './components/Intro';
import Photo from './components/Photo';
import Product from './components/Product';
import AboutUs from './components/AboutUs/AboutUs';
import Products from './components/Products/Products';
import Contact from './components/Contact/contact';
import LogIn from './components/LogIn/LogIn';
import Dashboard from './components/Admin/Dashboard';
import YonetimHeader from './components/Admin/YonetimHeader';
import Settings from './components/Admin/Settings';
import AddProducts from './components/Admin/AddProducts';
import Elevator from './components/Elevator';
import { auth } from './components/Firebase'; 
import { Teklifler } from './components/Admin/Teklifler';
import Whatsapp from './components/Whatsapp';

const PrivateRoute = ({ children }) => {
  const user = auth.currentUser;
  return user ? children : <Navigate to="/admin" />;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route 
          exact path="/" 
          element={
            <>
              <Header />
              <Intro />
              <HomeAbout />
              <Photo />
              <Elevator />
              <Info />
              <Product />
              <Brands />
              <Whatsapp/>
              <Footer />
            </>
          } 
        />
        <Route path="/hakkimizda" element={
          <>
            <Header />
            <AboutUs />
            <Footer />
          </>
        } />
        <Route path="/iletisim" element={
          <>
            <Header />
            <Contact />
            <Footer />
          </>
        } />
        <Route path="/urunler" element={
          <>
            <Header />
            <Products />
            <Footer />
          </>
        } />
        <Route path="/admin" element={<LogIn />} />
        <Route path="/yonetim/istatistikler" element={
          <PrivateRoute>
            <>
              <YonetimHeader />
              <Dashboard />
            </>
          </PrivateRoute>
        } />
        <Route path="/yonetim/ayarlar" element={
          <PrivateRoute>
            <>
              <YonetimHeader />
              <Settings />
            </>
          </PrivateRoute>
        } />
        <Route path="/yonetim/teklifler" element={
          <PrivateRoute>
            <>
              <YonetimHeader />
              <Teklifler />
            </>
          </PrivateRoute>
        } />
        <Route path="/yonetim/urunler" element={
          <PrivateRoute>
            <>
              <YonetimHeader />
              <AddProducts />
            </>
          </PrivateRoute>
        } />
      </Routes>
    </Router>
  );
}

export default App;