import React, { useEffect, useState } from 'react'
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from './../Firebase';
import { TiDelete } from "react-icons/ti";


export const Teklifler = () => {

    const [teklifler, setTeklifler] = useState([]);

    useEffect(() => {
        const fetchTeklifler = async () => {
          const tekliflerCollection = collection(db, "teklifler"); 
          const tekliflerSnapshot = await getDocs(tekliflerCollection);
          const tekliflerList = tekliflerSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setTeklifler(tekliflerList);
        };
    
        fetchTeklifler();
      }, []);

      const handleDelete = async (id) => {
       
        if (window.confirm('Bu teklifi silmek istediğinizden emin misiniz?')) {
          try {
            const teklifDocRef = doc(db, "teklifler", id);
            await deleteDoc(teklifDocRef);
            setTeklifler(teklifler.filter(teklif => teklif.id !== id));  
            console.log('Teklif başarıyla silindi');
          } catch (error) {
            console.error('Teklif silinirken bir hata oluştu:', error);
            alert('Teklif silinirken bir hata oluştu, lütfen tekrar deneyin.');
          }
        } else {
       
          console.log('Silme işlemi iptal edildi');
        }
      };

  return (
    <div className="p-8 bg-gray-100 min-h-screen ml-[218px]">
      <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="bg-white p-6 rounded-lg md:col-span-3 container mx-auto">
          <div className="flex justify-between items-start">
            <div>
              <h2 className="text-lg font-bold">Teklifler</h2>
              <h1>Siteden gelen teklifleri aşağıdan görüntüleyebilirsiniz.</h1>
            </div>
    
          </div>

          <div className="container mx-auto p-6">
            <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead className="bg-gray-800 text-white">
                <tr>
                  <th className="py-3 px-4 text-left">Ad</th>
                  <th className="py-3 px-4 text-left">Soyad</th>
                  <th className="py-3 px-4 text-left">Telefon</th>
                  <th className="py-3 px-4 text-left">İl</th>
                  <th className="py-3 px-4 text-left">Sil</th>
                </tr>
              </thead>
              <tbody>
                {teklifler.map(teklif => (
                  <tr key={teklif.id} className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-3 px-4">{teklif.name}</td>
                    <td className="py-3 px-4">{teklif.surname}</td>
                    <td className="py-3 px-4">{teklif.phone}</td>
                    <td className="py-3 px-4">{teklif.city}</td>
                    <td className="py-3 px-4">
                        <button onClick={()=>handleDelete(teklif.id)} className="hover:text-blue-900"><TiDelete size={33} /></button>
                    </td>
                    
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>

    
    </div>
  )
}
