import React from 'react'
import { FaInstagram } from 'react-icons/fa';
import { IoMdHeart } from "react-icons/io";

function Footer() {
  return (
    <div className="h-auto z-30">
      <div className="grid grid-cols-1 lg:grid-cols-5 gap-y-4 mx-auto container pt-40">  
        <nav className="w-56 mx-auto lg:mx-0">
          <a href="#">
            <img className="h-[70px]" src="./img/asfavorilogo.png" alt="logo" />
            <div className="flex w-10/12 mt-6 border-4 p-2 rounded-xl items-center gap-x-5 border-black justify-center text-center bg-white shadow-lg">
              <div className="text-4xl font-black">
                7/24
              </div>
              <div className="text-sm font-bold uppercase tracking-wide">
                ACİL<br/>SERVİS
              </div>
            </div>
          </a>
          <p className="mt-7 text-gray-700 ">Asansörde Güvenin Adı: <span className=" font-black">ASFAVORİ</span></p>
          <div className="flex gap-x-2 mt-7">  
            <a href='https://www.instagram.com/as.favori.asansor/'>
              <div className="bg-gray-300 h-[32px] w-[32px] rounded-full flex items-center justify-center hover:bg-blue-900 hover:text-white text-gray-400">
                <FaInstagram size={18} />
              </div>
            </a>
          </div>
        </nav>

        <nav className="hidden lg:flex flex-col">
          <h1 className="font-bold">Asansör Bakım</h1>
          <ul className="font-light space-y-4 mt-7">
            <li>Beylikdüzü Asansör Bakım</li>
            <li>Büyükçekmece Asansör Bakım</li>
            <li>Avcılar Asansör Bakım</li>
            <li>Mimaroba Asansör Bakım</li>
            <li>Güzelce Asansör Bakım</li>
            <li>Selimpaşa Asansör Bakım</li>
            <li>Bahçeşehir Asansör Bakım</li>
          </ul>
        </nav>

        <nav className="hidden lg:flex flex-col">
          <h1 className="font-bold">Kategoriler</h1>
          <ul className="font-light space-y-4 mt-7">
            <li>Asansör Montajı</li>
            <li>Asansör Periyodik Bakım</li>
            <li>Asansör Revizyon</li>
            <li>Asansör Modelleri</li>
            <li>Asansör Parçaları</li>
            <li>Modernizasyon Çözümleri</li>
            <li>Güvenlik ve Bakım Hizmetleri</li>
          </ul>
        </nav>

        <nav className="hidden lg:flex flex-col">
          <h1 className="font-bold">Hakkımızda</h1>
          <ul className="font-light space-y-4 mt-7">
            <li>Şirket Profili</li>
            <li>Vizyon ve Misyon</li>
            <li>Kariyer Fırsatları</li>
            <li>Sertifikalar</li>
            <li>Referanslar</li>
            <li>Markalar</li>
            <li>Kalite</li>
          </ul>
        </nav>

        <nav className="hidden lg:flex flex-col">
          <h1 className="font-bold">Diğer</h1>
          <ul className="font-light space-y-4 mt-7">
            <li>Sıkça Sorulan Sorular</li>
            <li>İletişim</li>
            <li>Gizlilik Politikası</li>
            <li>Kullanım Şartları</li>
            <li>Garanti</li>
            <li>Servis</li>
            <li>Teklif Al</li>
          </ul>
        </nav>
      </div>

      <a href='https://345.com.tr'>
        <div className="flex container mx-auto justify-center text-ucdortbes gap-x-1 mt-20 pb-5 items-center">
          <img className="h-[33px]" src="./img/345logo.png" alt="logo" />
          <h1 className="font-black text-black">made with</h1>
          <IoMdHeart size={26}/>
        </div>
      </a>
    </div>
  );
}

export default Footer;