import React, { useState, useEffect } from 'react';
import { BiArea } from "react-icons/bi";
import { collection, getDocs } from "firebase/firestore";
import { db } from '../Firebase';

const Products = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, "products"));
      const productsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProducts(productsList);
    };

    fetchProducts();
  }, []);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mx-auto container gap-y-16 mt-16 gap-x-4 px-4 sm:px-0">
      {products.map(product => (
        <div key={product.id} className="relative rounded-lg bg-black ease-in-out duration-300 hover:scale-105">
          {product.camp && (
            <div className="absolute top-2 left-2 right-0 text-center text-white bg-black bg-opacity-80 py-1 rounded-xl w-32 text-sm font-bold ">
              KAMPANYA
            </div>
          )}  
          <img src={product.imageUrl} alt="Görsel" className="h-[302px] w-full object-cover rounded-lg"/>
          <h1 className="text-center font-bold text-2xl mt-3 text-white">{product.name}</h1>
          <div className="flex text-l items-center font-semibold justify-center gap-4 mt-3 p-8 text-white pb-4">
            <div className="flex justify-center mb-5 text-base font-thin text-center">{product.feature}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Products;