import React, { useState } from 'react';
import { IoIosArrowBack} from "react-icons/io";
import { DiGoogleAnalytics } from "react-icons/di";
import { IoSettingsSharp } from "react-icons/io5";
import { FaBagShopping } from "react-icons/fa6";
import { GrElevator } from "react-icons/gr";


import { IoExit } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../Firebase';






const YonetimHeader = () => {

    const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/admin'); 
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return (
    <div className="w-[218px] fixed top-0 left-0 h-full bg-white flex flex-col justify-between ">
      <div className="w-full">
      <Link to="/" >
        <div className="text-sm text-gray-700 font-normal flex items-center space-x-1 px-3 py-2 justify-start hover:text-gray-400 cursor-pointer">
        
          <IoIosArrowBack size={18}/> 
          <h1>Siteye Dön</h1>
        
        </div>
        </Link>
        <div className="flex justify-center items-center mt-4 cursor-pointer">
          <img className="h-[49px] w-[106px ]" src="/img/345logo.png" alt="logo" />
        </div>
      </div>
      <ul className=" mt-16 space-y-8 w-full ">
      <Link to="/yonetim/istatistikler" >
        <li className="hover-gradient text-gray-700 font-semibold flex space-x-2 px-4 py-2  hover:text-ucdortbes cursor-pointer">
          <DiGoogleAnalytics className="ml-8" size={28}/> 
          <h1>İstatistikler</h1>
        </li>
        </Link>
        <Link to="/yonetim/urunler" >
        <li className="hover-gradient text-gray-700 font-semibold flex  space-x-2 px-4 py-2  hover:text-ucdortbes cursor-pointer">
          <FaBagShopping className="ml-8" size={28}/> 
          <h1>Hizmetler</h1>
        </li>
        </Link>
        <Link to="/yonetim/ayarlar" >
        <li className="hover-gradient text-gray-700 font-semibold flex  space-x-2 px-4 py-2  hover:text-ucdortbes cursor-pointer">
          <IoSettingsSharp className="ml-8" size={28}/> 
          <h1>Ayarlar</h1>
        </li>
        </Link>
        <Link to="/yonetim/teklifler" >
        <li className="hover-gradient text-gray-700 font-semibold flex  space-x-2 px-4 py-2  hover:text-ucdortbes cursor-pointer">
          <GrElevator className="ml-8" size={28}/> 
          <h1>Teklifler</h1>
        </li>
        </Link>
        
      </ul>
      <div className="flex items-center mb-4 w-full px-1">
        <img className="h-[53px] w-[53px] rounded-2xl" src="/img/bg.jpg" alt="profile" />
        <div className="ml-1">
          <h1 className="text-gray-800 font-bold text-sm">Furkan Tirit</h1>
          <h2 className="text-gray-800 font-light text-sm">ASFAVORİ</h2>
        </div>
        <div onClick={handleSignOut} className="ml-auto hover:bg-ucdortbes hover:rounded-full h-12 w-12 flex justify-center items-center hover:text-white hover-gradient2 cursor-pointer">
          <IoExit size={34}/>
        </div>
        
      </div>
    </div>
  );
}

export default YonetimHeader;